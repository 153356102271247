import React from 'react'
import { Script } from 'gatsby'

import 'swiper/css'
import 'swiper/css/scrollbar'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import './src/styles/style.css'

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <Script src="https://js.hsforms.net/forms/embed/v2.js" />
    </>
  )
}
